import axios from 'axios';
const state = {
    organization: null
};
const getters = {
    organization: state => !! state.organization
};
const actions = {

    getOrganizations({ commit }, User) {
        return new Promise((resolve, reject) => {

            axios.get('organization', User)
                .then(async (resp) => {
                    if (resp.data.success) {
                        await commit('setOrganization', resp.data);
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    },

};
const mutations = {
    setOrganization(state, organizations) {
        state.organization = organizations.data;
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};