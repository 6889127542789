import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import auth from './modules/auth';
import user from './modules/user';
import organization from './modules/organization';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
  modules:{
    auth,
    user,
    organization
  },
  plugins: [vuexLocal.plugin]
})
