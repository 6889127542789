import axios from 'axios';
const state = {
    users: null
};
const getters = {
    users: state => !!state.users
   
};
const actions = {
   
    fetchData({ commit }, query) {
        return new Promise((resolve, reject) => {

            axios.get('users', {
                params:query
            })
                .then(async (resp) => {
                    if (resp.data.success) {
                        await commit('setUsers', resp.data);
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                })
                .catch(err => {
                    reject(err);
                })

        })
    },

};
const mutations = {
    setUsers(state, users) {
        state.users = users.data;
      
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};