import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, digits } from 'vee-validate/dist/rules';
import VueApexCharts from 'vue-apexcharts';

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';

Vue.component('v-select', vSelect);

extend('email', {
  ...email,
  message: 'Invalid Email ID ',
});
extend('required', {
  ...required,
  message: 'This field is required',
});
extend('phone', {
  ...digits,
  message: 'Phone Number Length should be 10',
});

extend('digits', digits);

// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.filter('formatDate', (val) => {
  let date = val.substring(0, 10).split('-');
  return date[2] + '/' + date[1] + '/' + date[0];
  //
  //
  //
  // console.log(date);
  // console.log("date")
  // return (
  //   (date.getDate() > 9 ? "" : "0") +
  //   date.getDate() +
  //   "-" +
  //   (date.getMonth() + 1 > 9 ? "" : "0") +
  //   (date.getMonth() + 1) +
  //   "-" +
  //   date.getFullYear()
  // );
});
window.axios = axios;
// pagination
import Paginate from 'vuejs-paginate';
Vue.component('paginate', Paginate);

axios.defaults.baseURL = process.env.VUE_APP_URL;
// console.log('store.getters.getToken', store.getters.getToken);
// if (store.getters.getToken) {
//   axios.defaults.headers.common['Authorization'] = store.getters.getToken;
// }

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(require('vue-moment'));
axios.interceptors.request.use(
    (config) => {
      let token = store.getters.getToken;
      if (token) {
        config.headers['Authorization'] = `${ token }`;
      }

      return config;
    },

    (error) => {
      return Promise.reject(error);
    }
);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
