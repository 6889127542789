<template>
  <div>
    <b-sidebar
      class="sidebar"
      bg-variant="white"
      text-variant="success"
      aria-labelledby="Hosachiguri"
      no-header
      shadow
      width="298px"
      :no-close-on-route-change="true"
      :no-close-on-esc="true"
      visible
    >
      <img
        class="logo"
        src="@/assets/logo.png"
        alt="Hosachiguru"
        width="220px"
        height="100px"
      />

      <div>
        <nav class="mb-3">
          <b-nav vertical>
            <b-nav-item class="nav-item" to="/"
              ><i class="fas fa-house-user nav-icon"></i>Home</b-nav-item
            >
            <b-nav-item to="/users" class="nav-item">
              <i class="fas fa-user-friends nav-icon"></i>Users
            </b-nav-item>
            <b-nav-item to="/activities" class="nav-item"
              ><i class="fas fa-tractor nav-icon"></i> Activities</b-nav-item
            >
             <b-nav-item to="/announcement" class="nav-item">
              <i class="fas fa-user-friends nav-icon"></i>Announcements
            </b-nav-item>
          </b-nav>
        </nav>
      </div>
    </b-sidebar>

    <b-navbar toggleable="lg" variant="white" class="topnav">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <b-row no-gutters>
                <b-col cols="3">
                  <i
                    class="fas fa-user-circle user-icon"
                    aria-hidden="true"
                  ></i>
                </b-col>
                <b-col cols="8 mt-2">
                  <span class="name">{{ LoggedInName }}</span>
                  <br />
                </b-col>
              </b-row>
            </template>
            <b-dropdown-item to="/organization">Organization</b-dropdown-item>
            <b-dropdown-item href="#">Profile</b-dropdown-item>
            <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="container-fluid" style="padding-left: 298px">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLayout",
  components: {},
  computed: {
    LoggedInName: function() {
      return this.$store.getters.LoggedInName;
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch("LogOut");
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style>
.topnav {
  margin-top: 10px;
}
.topnav .name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #525252;
  letter-spacing: 3.5%;
  font-size: 18px;
}
.topnav .heading {
  color: #888888;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  height: 30px;
  line-height: 30px;
  letter-spacing: 3.5%;
  height: 27px;
}
.topnav .user-icon {
  height: 47px;
  width: 45px;
  margin-right: 14px;
}
.topnav .dropdown-toggle {
  outline: none;
}
.topnav .dropdown-toggle::after {
  display: none;
}
.sidebar .logo {
  margin: 33px 38px;
}
.sidebar .nav-item {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  width: 298px;
  height: 62px;
  font-size: 15px;
  letter-spacing: 3.5%;
  line-height: 30px;
  color: #656565;
}
.sidebar .nav-icon,
.nav-icon:hover {
  width: 21px;
  height: 15px;
  margin-right: 16px;
  color: #656565;
  margin-left: 34px;
  text-decoration: none;
}

.sidebar .nav-link {
  color: #656565;
  text-decoration: none;
}

.nav-item .router-link-exact-active {
  background-color: #1da040;
  width: 298px;
  height: 55px;
  color: #fff;
}

.nav-item .router-link-exact-active .nav-icon {
  color: #fff;
}
</style>
