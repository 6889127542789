import axios from 'axios';
const state = {
    name: null,
    token: null
};
const getters = {
    isAuthenticated: state => !!state.token,
    LoggedInName: state => state.name,
    getToken: state => state.token,
};
const actions = {
    async LogOut({ commit }) {
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        let user = null

        commit('logout', user)
    },
    LogIn({ commit }, User) {
        return new Promise((resolve, reject) => {

            axios.post('login', User)
                .then(async (resp) => {
                    if (resp.data.success) {
                        await commit('setUser', resp.data);
                        resolve(resp);
                    } else {
                        console.log("resp");
                        console.log(resp);
                        reject(resp);
                    }
                })
                .catch(err => {
                    reject(err);
                })

        })
    },

};
const mutations = {
    setUser(state, userinfo) {
        state.name = userinfo.name;
        state.token = userinfo.token
    },
    logout(state) {
        state.user = null
        state.token = null
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};