import Vue from 'vue';
import VueRouter from 'vue-router';
import AuthLayout from '../views/layouts/Auth';
import HomeLayout from '../views/layouts/App';
import store from '../store/index';
Vue.use(VueRouter);

const routes = [
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('../components/auth/login.vue'),
      },
    ],
  },
  {
    path: '/',
    component: HomeLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'user-stats',
        name: 'userStats',
        component: () => import('../views/UserStats.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'tree-view',
        name: 'treeView',
        component: () => import('../views/TreeView.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'announcement',
        name: 'socialMedia',
        component: () => import('../views/EmptyRouterView.vue'),
        meta: { requiresAuth: true },
        children: [
          { path: '', component: () => import('../views/SocialMedia.vue') },
          {
            path: ':id',
            component: () => import('../views/SocialMediaView.vue'),
          },
          {
            path: ':id/edit',
            component: () => import('../views/EmptyRouterView.vue'),
            children: [
              {
                path: '',
                component: () => import('../views/announcements/edit.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'activities',
        name: 'activity',
        component: () => import('../views/EmptyRouterView.vue'),
        meta: { requiresAuth: true },
        children: [
          { path: '', component: () => import('../views/Activity.vue') },
          {
            path: ':code/:assignment_level',
            component: () =>
              import('../views/activities/FilteredActivities.vue'),
          },
          {
            path: ':activity_id',
            component: () => import('../views/ActivityView.vue'),
          },
          {
            path: 'edit/activity/:activity_id',
            component: () => import('../views/activities/edit.vue'),
          },
        ],
      },
      {
        path: 'users',
        component: () => import('../views/EmptyRouterView'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            component: () => import('../views/users/List.vue'),
          },
          {
            path: '/filter/users',
            component: () => import('../views/users/FilteredList.vue'),
          },
          {
            path: ':id',
            component: () => import('../views/EmptyRouterView.vue'),
            children: [
              {
                path: '',
                component: () => import('../views/users/View.vue'),
              },
              {
                path: 'plot/create',
                component: () => import('../views/users/Plots/create.vue'),
              },
              {
                path: 'plot/:plot_id',
                component: () => import('../views/users/Plots/View.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'organization',
        component: () => import('../views/EmptyRouterView'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            component: () => import('../views/organization/View.vue'),
          },
        ],
      },
      {
        path: 'zone/:zone_id',
        component: () => import('../views/EmptyRouterView.vue'),
        children: [
          {
            path: '',
            component: () => import('../views/zones/list.vue'),
          },
        ],
      },
      {
        path: 'plot/:plot_id',
        component: () => import('../views/plots/view.vue'),
      },
      {
        path: 'projects',
        component: () => import('../views/EmptyRouterView.vue'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            component: () => import('../views/projects/list.vue'),
          },
          {
            path: 'resorts',
            component: () => import('../views/EmptyRouterView.vue'),
            children: [
              {
                path: '',
                component: () => import('../views/projects/resorts/list.vue'),
              },
              {
                path: ':id',
                component: () => import('../views/projects/resorts/view.vue'),
              },
              {
                path: ':id/edit',
                component: () => import('../views/projects/resorts/edit.vue'),
              },
              {
                path: ':id/rooms',
                component: () => import('../views/projects/resorts/rooms.vue'),
              },
            ],
          },
          {
            path: ':id',
            component: () => import('../views/EmptyRouterView.vue'),
            children: [
              {
                path: '',
                component: () => import('../views/projects/details.vue'),
              },
            ],
          },
          {
            path: ':id/edit',
            component: () => import('../views/EmptyRouterView.vue'),
            children: [
              {
                path: '',
                component: () => import('../views/projects/edit.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'assets',
        meta: {
          requiresAuth: true,
        },
        component: () => import('../views/EmptyRouterView.vue'),
        children: [
          {
            path: '',
            component: () => import('../views/Assets.vue'),
          },
          {
            path: ':id',
            component: () => import('../views/assets/View.vue'),
          },
        ],
      },
      {
        path: 'book-farm-retreat',
        name: 'book-farm-retreat',
        component: () => import('../views/EmptyRouterView.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('../views/projects/resorts/bookings/BookingList'),
          },
        ],
      },
      {
        path: 'farm-retreat-bookings',
        name: 'farm-retreat-bookings',
        component: () => import('../views/EmptyRouterView.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('../views/projects/resorts/bookings/AllBookings'),
          },
          {
            path: ':id',
            component: () =>
              import('../views/projects/resorts/bookings/BookingDetails'),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next('/auth/login');
  } else {
    next();
  }
});
export default router;
